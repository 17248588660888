import React, { useState, useEffect, useRef } from 'react'
import { serviceData } from './serviceData'
import SingleService from './SingleService'
import pointRight from './photoes/pointright.png'

function Services() {
  const [scroll, setScroll] = useState(0)
  useEffect(() => {
    window.addEventListener('scroll', () => setScroll(window.scrollY))
  }, [])
  const [anim, setAnim] = useState(false)

  const imgRef = useRef(null)
  useEffect(() => {
    if (
      !anim &&
      imgRef.current.getBoundingClientRect().y < window.innerHeight * 0.8
    ) {
      setAnim(true)
    }
  }, [scroll])
  return (
    <>
      <h1 ref={imgRef}>LEISTUNGEN</h1>
      {serviceData.map((service) => {
        return <SingleService key={service.id} {...service} />
      })}
      <div className="service">
        <div className={`service-info last-info ${anim ? 'apear-anim' : null}`}>
          <h2>Schulungen</h2>
          <p className={anim ? 'apear-anim' : null}>
            <span className="line-1">Bei Ihnen im Betrieb zu </span>
            <span className="line-2">verschiedenen Themen, z. B. </span>
            <span className="line-3">Arbeitssicherheit, </span>
            <span className="line-4">Brandschutz, Gefahrstoffen, </span>
            <span className="line-5">Verhütung von Unfällen und </span>
            <span className="line-6">Berufserkrankungen, </span>
            <span className="line-7">stattfinden.</span>
          </p>
        </div>
        <div className="last-img">
          <img src={pointRight} alt="man pointing right" />
        </div>
      </div>
    </>
  )
}

export default Services
